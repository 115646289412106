/* eslint-disable class-methods-use-this */

import HTTPWrapper from '../utils/HTTPWrapper';

class SchoolAPI {
  findSchool = (query) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools/find?q=${query}`
    });

  getSchoolDashboardData = (urn) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/mini_dashboard`
    });

  updateSchoolsInHouseData = (urn, data) =>
    HTTPWrapper.patch({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/schools_in_house_datum.json`,
      data
    });

  getTeamData = (urn) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/team.json`
    });

  getDBSInfo = (urn) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/dbs_info.json`
    });

  getMatchFundingData = (urn) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/match_funding_info.json`
    });

  getImpactDashboardData = (urn) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/impact_dashboard_data.json?`
    });

  getImpactTimelineData = (urn, page) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/impact_timeline_data.json?page=${page}`
    });
}

export default new SchoolAPI();
