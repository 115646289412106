/* eslint-disable class-methods-use-this */
import HTTPWrapper from '../utils/HTTPWrapper';

class SchoolsUserEventLogAPI {
  createLog = (data) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools_user_event_logs.json`,
      data
    });
}

export default new SchoolsUserEventLogAPI();
