/* eslint-disable class-methods-use-this */
import HTTPWrapper from '../utils/HTTPWrapper';

class CallbackAPI {
  requestCallback = (data) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/request_callback.json`,
      data
    });
}

export default new CallbackAPI();
