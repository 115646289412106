/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@rocksteady-music-school/rms-ui';
import { SkeletonTable } from '../SkeletonTable/SkeletonTable';
import Timetable from '../Timetable/Timetable';
import DateUtils from '../../utils/DateUtils';
import TimetableAPI from '../../api/TimetableAPI';
import StringUtils from '../../utils/StringUtils';
import EmailTimetableModal from '../EmailTimetableModal/EmailTimetableModal';

const setInitialRange = () => [DateUtils.nWeeksBeforeNow(1), DateUtils.nWeeksFromNow(6)];

export default function TimetableContainer({
  id,
  ttDayTimeSlot,
  bandsDataLoading,
  bandsData,
  setFetchError
}) {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const [dateRange, setDateRange] = useState(() => setInitialRange());
  const [pdfLoading, setPdfLoading] = useState(false);
  const [timetableDataLoading, setTimetableDataLoading] = useState(true);
  const [timetableData, setTimetableData] = useState(null);
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const isFirstRender = useRef(true);

  let pdfWindow;

  const lastScheduledDate = timetableData?.lastScheduledDate;
  const firstScheduledDate = timetableData?.firstScheduledDate;

  const timetableDateParams = () => ({
    start_date: dateRange[0],
    end_date: dateRange[1]
  });

  const getTTData = () => {
    setTimetableDataLoading(true);

    TimetableAPI.getTTData(id, timetableDateParams())
      .then((res) => {
        setTimetableData(res.data);
        setTimetableDataLoading(false);
      })
      .catch(() => setFetchError(true));
  };

  useEffect(() => {
    getTTData();
  }, [dateRange, id]);

  // When we switch between timetables, reset the date range to be the current one
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      // Return early if it's the first render.
      // The date range has already been initialised correctly, so there's no need to set it again on the first render,
      // as this will cause an unecessary re-render and it will trigger getTTData again
      return;
    }

    setDateRange(setInitialRange());
  }, [id]);

  const noFutureDatesAvailable = () => {
    const date = dateRange[1];
    const currentEnd = new Date(date.getTime());

    if (!lastScheduledDate) return true;

    const noMoreFutureDates = lastScheduledDate < DateUtils.isoFormatDate(currentEnd);
    return noMoreFutureDates;
  };

  const noPastDatesAvailable = () => {
    const date = dateRange[0];
    const currentStart = new Date(date.getTime());

    if (!firstScheduledDate) return true;

    const noMorePastDates = firstScheduledDate > DateUtils.isoFormatDate(currentStart);
    return noMorePastDates;
  };

  const bumpUpFiveWeeks = () => {
    const date = dateRange[1];
    const currentEnd = new Date(date.getTime());

    const bumpedEnd = DateUtils.nWeeksFromDate(currentEnd, 5);
    const bumpedStart = DateUtils.nWeeksBeforeDate(currentEnd, 7);
    setDateRange([bumpedStart, bumpedEnd]);
  };

  const bumpDownFiveWeeks = () => {
    const date = dateRange[0];
    const currentStart = new Date(date.getTime());

    const bumpedStart = DateUtils.nWeeksBeforeDate(currentStart, 5);
    const bumpedEnd = DateUtils.nWeeksFromDate(currentStart, 7);
    setDateRange([bumpedStart, bumpedEnd]);
  };

  const bumpToTodaysDate = () => setDateRange(setInitialRange());

  const fetchAndOpenPDF = () => {
    TimetableAPI.getTTPdf(id, timetableDateParams())
      .then(async (response) => {
        // Create a Blob from the encoded PDF string
        const blob = await StringUtils.b64toBlob(response.data.pdf, 'application/pdf');
        //  Build a URL from the file
        const blobUrl = URL.createObjectURL(blob);
        //  Open the URL in a new window
        pdfWindow.location.href = blobUrl;
      })
      .catch(() => {
        setFetchError(true);
        pdfWindow.close();
      })
      .finally(() => {
        closeSnackbar();
        setPdfLoading(false);
      });
  };

  const setTemporaryWindowContent = () =>
    pdfWindow.document.write(
      '<html><head><title>Rocksteady Timetable</title></head><body height="100%" width="100%"><p>Your timetable PDF is being generated. This may take a few seconds...</p></body></html>'
    );

  const handleOpeningPDF = () => {
    pdfWindow = window.open('', '_blank');
    setTemporaryWindowContent(pdfWindow);

    setPdfLoading(true);
    enqueueSnackbar('Your timetable PDF is being generated. This may take a few seconds.', {
      variant: 'info',
      persist: true
    });
    fetchAndOpenPDF(pdfWindow);
  };

  const printAndEmailButtons = () => (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      width={{ xs: '100%', sm: 'initial' }}
      justifyContent="flex-start">
      <Button
        color="primary"
        label="Email Timetable"
        iconName="mail"
        onClick={() => setEmailModalVisible(true)}
        sx={{
          px: 16,
          marginRight: { xs: 0, sm: 2 },
          marginBottom: { xs: 5, sm: 0 },
          marginTop: { xs: 10, sm: 0 },
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0
        }}
      />

      <Button
        loading={pdfLoading}
        onClick={handleOpeningPDF}
        color="primary"
        label="Print Timetable"
        iconName="print"
        sx={{ px: 16, borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
      />
    </Box>
  );

  const heading = () => (
    <Box
      display="flex"
      flexWrap="wrap"
      gap={10}
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      alignItems="center"
      mb={16}>
      <Typography variant="h1" sx={{ color: 'primary.main' }}>
        Your timetable - {ttDayTimeSlot}
      </Typography>
      {printAndEmailButtons()}
    </Box>
  );

  const timetableNavigationButtons = () => (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      mb={16}>
      <Typography
        variant={noPastDatesAvailable() ? 'bodyLinkDisabled' : 'bodyLink'}
        onClick={noPastDatesAvailable() ? null : bumpDownFiveWeeks}
        sx={{ marginBottom: { xs: 5, sm: 0 }, textAlign: 'center' }}>
        {'< '}Previous 5 weeks
      </Typography>

      <Typography
        variant="bodyLink"
        color="primary.main"
        onClick={bumpToTodaysDate}
        sx={{ marginBottom: { xs: 5, sm: 0 }, textAlign: 'center' }}>
        Current Period
      </Typography>

      <Typography
        variant={noFutureDatesAvailable() ? 'bodyLinkDisabled' : 'bodyLink'}
        onClick={noFutureDatesAvailable() ? undefined : bumpUpFiveWeeks}
        sx={{ textAlign: 'center' }}>
        Next 5 weeks{' >'}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ bgcolor: 'whiteWedding.main' }} mb={40}>
      {heading()}
      {timetableNavigationButtons()}
      <Box
        sx={{
          overflow: 'hidden',
          height: '340px',
          borderRadius: '2px',
          outline: '1px solid #b9b9b9'
        }}>
        {timetableDataLoading || bandsDataLoading ? (
          <SkeletonTable />
        ) : (
          <Timetable timetableData={timetableData} bandsData={bandsData} dateRange={dateRange} />
        )}
      </Box>
      {emailModalVisible && (
        <EmailTimetableModal
          timetableId={id}
          timetableDateParams={timetableDateParams()}
          setEmailModalVisible={setEmailModalVisible}
        />
      )}
    </Box>
  );
}
