/* eslint-disable react/prop-types */
import { Alert, Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { Modal } from '@rocksteady-music-school/rms-ui';
import SchoolAPI from '../../api/SchoolAPI';
import { useUserData } from '../../contexts/UserContext';
import { useAuth } from '../../contexts/AuthContext';
import StringUtils from '../../utils/StringUtils';
import LogoPreview from './LogoPreview';
import ImagePicker from './ImagePicker';

export default function LogoModal({ setLogoModalOpen }) {
  const { schoolLogo, schoolUrn } = useUserData();
  const { setUser } = useAuth();

  const schoolLogoUrl = schoolLogo ? StringUtils.pathToAbbeyRoadUrl(schoolLogo) : null;

  const [logoUploading, setLogoUploading] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [uploadErrorMsg, setUploadErrorMsg] = useState(null);

  // Initialise the imgData with the existing logo if there is one
  // This is what will appear in the image preview
  const [imgData, setImgData] = useState(schoolLogo ? schoolLogoUrl : null);

  // If we have the school logo but no imgData, it means the user has chosen to delete the logo
  const logoHasBeenDeleted = schoolLogo && !imgData;

  const enableSavingChanges = () => {
    // If there is an attached image,
    // it means that the user has just added a new image in the modal
    // and they should be allowed to save it
    if (attachment) return true;

    // If there's no attachment, check if the logo has been deleted
    // and if so, allow the user to save their changes
    return logoHasBeenDeleted;
  };

  const logoUploadParams = () => {
    if (attachment) {
      const formData = new FormData();
      formData.append('schools_in_house_datum[logo]', attachment);
      return formData;
    }

    return { schools_in_house_datum: { logo: null } };
  };

  const updateUserDataWithNewLogo = (newLogoUrl) =>
    setUser((currentUserData) => ({
      ...currentUserData,
      attributes: {
        ...currentUserData.attributes,
        school: {
          ...currentUserData.attributes.school,
          logo: newLogoUrl
        }
      }
    }));

  const handleLogoUpload = () => {
    setLogoUploading(true);

    return SchoolAPI.updateSchoolsInHouseData(schoolUrn, logoUploadParams())
      .then((res) => {
        const newLogoUrl = res.data.data.attributes.logo_url;
        updateUserDataWithNewLogo(newLogoUrl);
        setLogoUploading(false);
        setLogoModalOpen(false);
      })
      .catch((e) => {
        setLogoUploading(false);
        setUploadErrorMsg(e.response?.data?.errors || e.message);
      });
  };

  const modalTitle = () => (
    <Typography variant="body" component="p" mb={24}>
      It will be updated for all contacts on your Schools Portal account.
    </Typography>
  );

  const logoPreview = () => (
    <Grid item xs={5}>
      <LogoPreview
        setAttachment={setAttachment}
        setImgData={setImgData}
        imgData={imgData}
        setUploadErrorMsg={setUploadErrorMsg}
      />
    </Grid>
  );

  const errorContainer = () =>
    uploadErrorMsg && (
      <Alert sx={{ marginBottom: 9 }} severity="error">
        {uploadErrorMsg}
      </Alert>
    );

  const imagePicker = () => (
    <Grid item xs={7} pr={43} alignSelf={uploadErrorMsg ? 'flex-start' : undefined}>
      {errorContainer()}
      <ImagePicker
        setImgData={setImgData}
        setAttachment={setAttachment}
        setUploadErrorMsg={setUploadErrorMsg}
      />
      <Typography variant="bodySecondary" component="p">
        Support .JPG, .JPEG, .PNG and .GIF with max of 5MB and at least 300x300px.
      </Typography>
    </Grid>
  );

  const modalContent = () => (
    <Box>
      {modalTitle()}
      <Grid container display="flex" alignItems="center">
        {logoPreview()}
        {imagePicker()}
      </Grid>
    </Box>
  );

  const buttonProps = [
    {
      label: 'Save change',
      color: 'secondary',
      disabled: !enableSavingChanges(),
      onClick: handleLogoUpload,
      loading: logoUploading
    }
  ];

  return (
    <Modal
      open
      onClose={() => setLogoModalOpen(false)}
      modalEntities={[
        {
          title: 'Change your school profile picture',
          content: modalContent(),
          buttonProps,
          justifyButtons: 'flex-end'
        }
      ]}
    />
  );
}
