/* eslint-disable class-methods-use-this */
import HTTPWrapper from '../utils/HTTPWrapper';

class FeelGoodFridayAPI {
  getFeelGoodFridayData = () =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/feel_good_friday.json`
    });
}

export default new FeelGoodFridayAPI();
