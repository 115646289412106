import React from 'react';
import { useUserData } from '../contexts/UserContext';
import TimetablePage from './TimetablePage';
import FeelGoodFridayPage from './FeelGoodFridayPage';

export default function HomePage() {
  const { isAdminOrStaff, isActiveSchool } = useUserData();

  return isActiveSchool && isAdminOrStaff ? <TimetablePage /> : <FeelGoodFridayPage />;
}
