/* eslint-disable no-use-before-define */
import { useState, useEffect } from 'react';
import {
  Typography,
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  TextField,
  FormGroup,
  Button
} from '@mui/material';
import { useOutletContext, Link } from 'react-router-dom';
import AuthContainer from '../components/AuthContainer';
import UserAPI from '../api/UserAPI';
import StringUtils from '../utils/StringUtils';
import WindowUtils from '../utils/WindowUtils';
import paths from '../consts/paths';

function UnlockAccountPage() {
  const [slideInRef] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [accountUnlocked, setAccountUnlocked] = useState(false);
  const [email, setEmail] = useState('');
  const [instructionsSent, setInstructionsSent] = useState(false);

  const fetchAccountUnlock = () => {
    const params = StringUtils.parseQueryString(WindowUtils.anyParams());
    setLoading(true);
    UserAPI.unlockAccount(params.token)
      .then(() => {
        setAccountUnlocked(true);
      })
      .catch((err) => {
        setErrors(err.response.data.errors);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAccountUnlock();
  }, []);

  const errorMessage = () => {
    if (errors) {
      const messages = errors.map((err) => <li key={err}>{err}</li>);
      return (
        <Alert severity="error">
          <AlertTitle>There was a problem</AlertTitle>
          <ul>{messages}</ul>
        </Alert>
      );
    }
    return null;
  };

  const heading = () => (
    <Typography variant="h1" sx={styles.heading}>
      Unlock Account
    </Typography>
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setErrors(null);
    setLoading(true);
    const params = { schools_portal_user: { email } };
    UserAPI.resendUnlockInstructions(params)
      .then(() => {
        setInstructionsSent(true);
      })
      .catch(() => {
        setErrors(['Unable to complete request']);
      })
      .finally(() => setLoading(false));
  };

  const handleEmailChange = (e) => setEmail(e.target.value);

  const instructionsSentContent = () => (
    <Box>
      <Alert>
        <p>
          If your email address exists, you will receive an email shortly with instructions to
          unlock your account.
        </p>
      </Alert>
      <Link to={paths.LOG_IN}>
        <Button color="secondary" style={styles.submitBtn}>
          Log In
        </Button>
      </Link>
    </Box>
  );

  const loadingContent = () => (
    <Box display="flex" justifyContent="center">
      <CircularProgress sx={{ color: 'black' }} />
    </Box>
  );

  const requestNewInstructionsContent = () => (
    <Box component="form" onSubmit={handleFormSubmit} noValidate>
      <FormGroup>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          type="email"
          autoFocus
          onChange={handleEmailChange}
          helperText="Please enter your email address"
        />
        <Button
          type="submit"
          color="secondary"
          label="Resend Instructions"
          style={styles.submitBtn}
          loading={loading.toString()}>
          Resend Unlock Instructions
        </Button>
      </FormGroup>
    </Box>
  );

  const accountUnlockedContent = () => (
    <div style={{ marginBottom: 20 }}>
      <p>Your account was successfully unlocked.</p>
      <Link to={paths.LOG_IN}>
        <Button color="secondary" style={styles.submitBtn}>
          Log In
        </Button>
      </Link>
    </div>
  );

  const pageContent = () => {
    if (loading) {
      return loadingContent();
    }
    if (errors) {
      return requestNewInstructionsContent();
    }
    if (accountUnlocked) {
      return accountUnlockedContent();
    }
    if (instructionsSent) {
      return instructionsSentContent();
    }
    return null;
  };

  return (
    <AuthContainer containerRef={slideInRef} animationSlidesIn>
      {heading()}
      {errorMessage()}
      <Box display="flex" mt={30}>
        {pageContent()}
      </Box>
    </AuthContainer>
  );
}

const styles = {
  heading: { mb: 16, mt: 50 },
  formGroup: { width: { lg: '60%', xs: '100%' } },
  submitBtn: { width: 'fit-content', mt: 10 }
};

export default UnlockAccountPage;
