/* eslint-disable class-methods-use-this */

import HTTPWrapper from '../utils/HTTPWrapper';

class SchoolSubmittedKidDatumAPI {
  postFormData = (data) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/school_submitted_kid_data`,
      data
    });
}

export default new SchoolSubmittedKidDatumAPI();
