/* eslint-disable react/prop-types */
import { Icon, Link, Stack, Typography } from '@mui/material';
import cloudDownloadIcon from '../../assets/images/cloud_download.svg';
import { useUserData } from '../../contexts/UserContext';
import { useAuth } from '../../contexts/AuthContext';
import SchoolsUserEventLogAPI from '../../api/SchoolsUserEventLogAPI';

export default function KeyResourceSegment({ fileName, fileUrl }) {
  const documentUrl = `${process.env.REACT_APP_ABBEY_ROAD_URL}${fileUrl}`;
  const { isActiveSchool, schoolUrn } = useUserData();
  const { user } = useAuth();

  const formatFileNameWithExtension = () => {
    const [extension] = fileUrl.match(/\.\w+$/);
    return `${fileName} (${extension})`;
  };

  const logAccess = () => {
    SchoolsUserEventLogAPI.createLog({
      schools_user_event_log: {
        schools_portal_user_id: user.id,
        school_is_active_rs: isActiveSchool,
        urn: schoolUrn,
        event_type: 'resource',
        event_item: 'download',
        value: 0,
        description: fileName
      }
    });
  };

  return (
    <Link href={documentUrl} target="_blank" rel="noopener noreferrer" onClick={logAccess}>
      <Stack
        direction="row"
        spacing={16}
        px={24}
        py={16}
        sx={{ backgroundColor: 'blueSuedeShoes.5', borderRadius: 2 }}
        alignItems="center">
        <Icon>
          <img src={cloudDownloadIcon} alt="" width={24} height={24} />
        </Icon>
        <Typography variant="bodyLink" noWrap>
          {formatFileNameWithExtension()}
        </Typography>
      </Stack>
    </Link>
  );
}
