/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Grid, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Page, ContentMissingPlaceholder } from '@rocksteady-music-school/rms-ui';
import thumbnail from '../assets/images/gazing_microphone_kids.png';
import DocumentsAPI from '../api/DocumentsAPI';
import response from '../consts/actions';
import KeyResourceSegment from '../components/KeyResourceSegment/KeyResourceSegment';
import paths from '../consts/paths';

export default function KeyResourcesPage() {
  const theme = useTheme();
  const [documents, setDocuments] = useState({});
  const [fetchStatus, setFetchStatus] = useState(null);

  const contentLoaded = fetchStatus === response.SUCCESS;

  const documentIcon = (
    <FontAwesomeIcon
      key="fa-file-alt"
      icon={faFileAlt}
      fontSize={40}
      color={theme.palette.paintItBlack[40]}
    />
  );

  const documentsExist = Object.values(documents).some((category) => category.length > 0);

  const fetchDocuments = () => {
    DocumentsAPI.getDocuments()
      .then((res) => {
        setDocuments(res.data);
        setFetchStatus(response.SUCCESS);
      })
      .catch(() => setFetchStatus(response.ERROR));
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const renderSkeletonSection = () => (
    <>
      <Skeleton variant="text" sx={{ fontSize: '2rem', mb: 24, width: '50%' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem', mb: 8 }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem', mb: 8 }} />
    </>
  );

  const renderLoading = () => (
    <Box width="70%">
      {renderSkeletonSection()}
      {renderSkeletonSection()}
      {renderSkeletonSection()}
    </Box>
  );

  const renderPageTitleSection = () => (
    <Stack spacing={16} mb={32}>
      <Typography variant="h1">Key Resources</Typography>
      <Typography variant="body" maxWidth={{ sm: '90%' }}>
        Here you&apos;ll find important documents relating to terms of service with Rocksteady, as{' '}
        well as our safeguarding policy and more.
      </Typography>
    </Stack>
  );

  const renderNoDocuments = () => (
    <Box maxWidth={{ md: '85%' }} height={{ md: 360, xs: 120 }}>
      <ContentMissingPlaceholder icons={[documentIcon]} text="documents are coming soon!" />
    </Box>
  );

  const renderCategorySections = () =>
    Object.entries(documents).map(([categoryName, categoryDocuments]) => {
      if (categoryDocuments.length > 0) {
        return (
          <Stack key={uuidv4()} spacing={16} mb={24}>
            <Typography variant="h3">{categoryName}</Typography>
            <Stack spacing={8} maxWidth={{ sm: '80%' }}>
              {categoryDocuments.map(({ name, file_url: fileUrl }) => (
                <KeyResourceSegment key={uuidv4()} fileName={name} fileUrl={fileUrl} />
              ))}
            </Stack>
          </Stack>
        );
      }
      return null;
    });

  const renderContactCard = () => (
    <Box maxWidth={450}>
      <Card
        altText="two kids with microphones gazing at each other"
        buttonProps={{
          color: 'secondary',
          href: paths.CONTACTS,
          label: 'Contact Schools Hotline'
        }}
        thumbnail={thumbnail}
        summary="Contact Schools Hotline for more information."
        title="Don't see what you need?"
      />
    </Box>
  );

  const renderContent = () => (documentsExist ? renderCategorySections() : renderNoDocuments());

  return (
    <Page error={fetchStatus === response.ERROR}>
      <Grid container sx={{ minHeight: '60vh' }} pt={{ lg: 24 }} spacing={{ lg: 24 }}>
        <Grid item sm={12} md={7}>
          {renderPageTitleSection()}
          {contentLoaded ? renderContent() : renderLoading()}
        </Grid>
        <Grid item sm={12} md={5} display="flex" justifyContent={{ sm: 'center', md: 'flex-end' }}>
          {renderContactCard()}
        </Grid>
      </Grid>
    </Page>
  );
}
