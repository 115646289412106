/* eslint-disable no-use-before-define */

import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TimelineEntry, Paginator } from '@rocksteady-music-school/rms-ui';
import { useUserData } from '../../contexts/UserContext';
import SchoolAPI from '../../api/SchoolAPI';

export default function ImpactTimelineContainer({ setFetchError }) {
  const { schoolUrn } = useUserData();
  const [dataLoading, setDataLoading] = useState(false);
  const [timelineData, setTimelineData] = useState(null);
  const [page, setPage] = useState(1);

  const fetchImpactTimelineData = () => {
    setDataLoading(true);
    SchoolAPI.getImpactTimelineData(schoolUrn, page)
      .then((res) => {
        setTimelineData(res.data);
        setDataLoading(false);
      })
      .catch(() => setFetchError(true));
  };

  useEffect(() => {
    if (schoolUrn) {
      fetchImpactTimelineData();
    }
  }, [schoolUrn, page]);

  const title = () => (
    <Typography variant="h2" color="primary.main" mb={{ xs: '8px', sm: null }}>
      Timeline
    </Typography>
  );

  const placeholderEntries = () =>
    [1, 2, 3].map((i) => <TimelineEntry loadingPlaceholder key={i} />);

  const entries = () =>
    timelineData?.timeline_entries.data.map((entry) => (
      <TimelineEntry
        body={entry.attributes.body}
        category={entry.attributes.category}
        color={entry.attributes.color}
        createdAt={entry.attributes.created_at}
        pinned={entry.attributes.pinned}
        vimeoId={entry.attributes.vimeo_id}
        key={uuidv4()}
      />
    ));

  const header = () => (
    <Box width="100%" sx={styles.header}>
      {title()}
      {timelineData && (
        <Box>
          <Paginator
            totalPages={timelineData.pagination_data.total_pages}
            displayCount={timelineData.pagination_data.display_text}
            setPage={setPage}
            page={page}
          />
        </Box>
      )}
    </Box>
  );

  const footer = () =>
    timelineData && (
      <Box alignItems="center">
        <Paginator
          totalPages={timelineData.pagination_data.total_pages}
          displayCount={timelineData.pagination_data.display_text}
          setPage={setPage}
          page={page}
        />
      </Box>
    );

  const content = () => {
    return (
      <Box mt={45}>
        {header()}
        {dataLoading ? placeholderEntries() : entries()}
        {footer()}
      </Box>
    );
  };

  return content();
}

const styles = {
  header: {
    display: { xs: null, sm: 'flex' },
    justifyContent: 'space-between',
    marginBottom: { xs: '16px', sm: '16px' }
  }
};
