/* eslint-disable no-use-before-define */
import { Container, Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Footer } from '@rocksteady-music-school/rms-ui';
import paths from '../consts/paths';
import { useAuth } from '../contexts/AuthContext';
import CookieConsentModal from '../components/CookieConsentModal/CookieConsentModal';
import { ResourceHubSiteHeader } from '../components/ResourceHub/ResourceHubSiteHeader';
import hubBackground from '../assets/images/hub_background.png';

export default function LayoutHub() {
  const { isAuthenticated, getCurrentUserPending } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect the user to the Resource Hub screen if they're logged in and trying to access a pre-login page
    if (isAuthenticated) navigate(paths.RESOURCE_HUB, { replace: true });
  }, []);

  const loadingIndicator = () => (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="70vh">
      <CircularProgress sx={{ color: 'white' }} />
    </Box>
  );

  const mainContent = () => {
    if (getCurrentUserPending) return loadingIndicator();

    return <Outlet />;
  };

  const pageHeaderSchoolsResources = () => <ResourceHubSiteHeader />;

  return (
    <Container sx={{ ...styles.mainContainer, px: { xs: 0 } }}>
      <Box sx={styles.contentContainer}>
        {/* HEADER */}
        {pageHeaderSchoolsResources()}
        <Box sx={styles.innerContainer} px={{ xs: 20, sm: 20, md: 30, lg: 0 }}>
          {/* MAIN PAGE */}
          <Box className="video-resource-hub" marginTop={{ xs: 40, sm: 40, md: 0 }} flexGrow={1}>
            {mainContent()}
          </Box>

          {/* FOOTER */}
          <Box mx={30}>
            <Footer isAuthenticated={isAuthenticated} />
          </Box>
        </Box>

        {/* MODALS */}
        <CookieConsentModal />
      </Box>
    </Container>
  );
}

const styles = {
  mainContainer: {
    minHeight: '100vh',
    minWidth: '100vw',
    margin: 0,
    padding: 0,
    backgroundImage: `url(${hubBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  contentContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 1200
  }
};
