/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { Button } from '@rocksteady-music-school/rms-ui';
import InfoIcon from '@mui/icons-material/Info';
import { useUserData } from '../../contexts/UserContext';
import { useAuth } from '../../contexts/AuthContext';
import SchoolAPI from '../../api/SchoolAPI';
import paths from '../../consts/paths';
import DateUtils from '../../utils/DateUtils';
import SatsPreferences from './SatsPreferences';

function RecruitmentTooltipText() {
  return (
    <Box maxWidth={500}>
      <p>
        Supporting us in our Bandleader recruitment process is a great way to receive some free
        Rocksteady lessons at your school (especially for kids not already enrolled with us)!
      </p>
      <p>
        If this sounds like something you would be able to help us with, ensure you have this option
        ticked, and we will likely be in touch to talk you through the process.
      </p>
    </Box>
  );
}

export default function UpdateUserPrefsSection({ type }) {
  const {
    optedInForMarketing: currentOptInValue,
    dpa: dpaInPlace,
    dpaSignedBy,
    dpaSignedOn,
    firstName,
    lastName,
    schoolName,
    schoolUrn,
    satsPreferences: currentSatsPreferences,
    canHostRecruitmentLessons: currentCanHostRecruitmentLessons
  } = useUserData();

  const { updateSchoolContact, setUser } = useAuth();

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const [optingInForMarketing, setOptingInForMarketing] = useState(currentOptInValue);
  const [dpaAccepted, setDpaAccepted] = useState(dpaInPlace || false);
  const [satsPreferences, setSatsPreferences] = useState(currentSatsPreferences);
  const [canHostRecruitmentLessons, setCanHostRecruitmentLessons] = useState(
    currentCanHostRecruitmentLessons
  );
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ user: null, school: null });

  useEffect(() => {
    closeSnackbar();
  }, [dpaAccepted, optingInForMarketing]);

  const satsPreferencesHaveChanged = useMemo(
    () => !_.isEqual(currentSatsPreferences, satsPreferences),
    [currentSatsPreferences, satsPreferences]
  );

  const handleErrorResponse = (error) => {
    if (error.response?.data?.errors) {
      setErrors((currentErrors) => ({
        ...currentErrors,
        [type]: [error.response.data.errors].flat()
      }));
    } else {
      setErrors((currentErrors) => ({ ...currentErrors, [type]: [error.message].flat() }));
    }
  };

  const updateUserDataWithNewValues = (sihdValues) => {
    const newDpa = sihdValues?.data_processing_agreement_in_place;
    const newDpaSignedBy = sihdValues?.data_processing_agreement_signed_by;
    const newDpaSignedOn = sihdValues?.data_processing_agreement_signed_on;
    const newDpaSigneeEmail = sihdValues?.data_processing_agreement_signee_email;
    const newSatsPreference = sihdValues?.sats_preference;
    const newKs1SatsPreference = sihdValues?.ks1_sats_on_rs_days;
    const newSatsRoomChangeNotes = sihdValues?.sats_room_change_notes;
    const newCanHostRecruitmentLessons = sihdValues?.can_host_recruitment_lessons;

    setUser((currentUserData) => ({
      ...currentUserData,
      attributes: {
        ...currentUserData.attributes,
        school: {
          ...currentUserData.attributes.school,
          dpa: newDpa,
          dpa_signed_by: newDpaSignedBy,
          dpa_signed_on: newDpaSignedOn,
          dpa_signee_email: newDpaSigneeEmail,
          sats_preference: newSatsPreference,
          ks1_sats_on_rs_days: newKs1SatsPreference,
          sats_room_change_notes: newSatsRoomChangeNotes,
          can_host_recruitment_lessons: newCanHostRecruitmentLessons
        }
      }
    }));
  };

  const displaySnackMessage = () =>
    enqueueSnackbar('Done! Your preferences have been updated.', { variant: 'success' });

  const updateMarketingPreferences = () => {
    const params = {
      school_contact: { opted_in_for_marketing: optingInForMarketing }
    };

    updateSchoolContact(params)
      .then(displaySnackMessage)
      .catch(handleErrorResponse)
      .finally(() => setLoading(false));
  };

  const updateSihd = () => {
    const params = {
      schools_in_house_datum: {
        data_processing_agreement_in_place: dpaAccepted,
        sats_preference: satsPreferences.sats_preference,
        ks1_sats_on_rs_days: satsPreferences.ks1_sats_on_rs_days,
        sats_room_change_notes: satsPreferences.sats_room_change_notes,
        can_host_recruitment_lessons: canHostRecruitmentLessons
      }
    };

    SchoolAPI.updateSchoolsInHouseData(schoolUrn, params)
      .then((res) => {
        const newSihdValues = res.data?.data?.attributes;
        updateUserDataWithNewValues(newSihdValues);
        displaySnackMessage();
      })
      .catch((e) => handleErrorResponse(e))
      .finally(() => setLoading(false));
  };

  const schoolPrefsChanged = useMemo(
    () =>
      dpaAccepted !== dpaInPlace ||
      satsPreferencesHaveChanged ||
      currentCanHostRecruitmentLessons !== canHostRecruitmentLessons,
    [
      dpaAccepted,
      dpaInPlace,
      satsPreferencesHaveChanged,
      currentCanHostRecruitmentLessons,
      canHostRecruitmentLessons
    ]
  );

  const handleSubmit = () => {
    setErrors({ user: null, school: null });
    setLoading(true);

    if (optingInForMarketing !== currentOptInValue) updateMarketingPreferences();
    if (schoolPrefsChanged) updateSihd();
  };

  const alertMsgs = () =>
    errors[type].length > 1 ? (
      <ul style={{ listStylePosition: 'inside', paddingLeft: 0, marginTop: 8, marginBottom: 2 }}>
        {errors[type].map((er) => (
          <li key={uuidv4()}>{er}</li>
        ))}
      </ul>
    ) : (
      errors[type]
    );

  const renderErrorAlert = () => (
    <Alert severity="error">
      <AlertTitle>Error saving preferences</AlertTitle>
      {alertMsgs(type)}
    </Alert>
  );

  const checkboxField = (accepted, setAccepted, text, disabled = false) => {
    return (
      <FormGroup sx={{ display: 'block' }}>
        <FormControlLabel
          sx={{ display: 'table' }}
          control={
            <div style={{ display: 'table-cell' }}>
              <Checkbox
                disabled={disabled || loading}
                name={type}
                checked={accepted}
                onChange={() => setAccepted(!accepted)}
              />
            </div>
          }
          label={
            <Typography variant="body" component="p" color="fadeToGrey.main">
              {text}
            </Typography>
          }
        />
      </FormGroup>
    );
  };

  const dpaSignedDetails = () => {
    if (dpaSignedBy && dpaSignedOn)
      return ` by ${dpaSignedBy} on ${DateUtils.formattedDateParts(dpaSignedOn).join(' ')}`;

    return '';
  };

  const dpaText = () =>
    dpaInPlace ? (
      <>
        The{' '}
        <a href={paths.KEY_RESOURCES} target="_blank" rel="noreferrer">
          <Typography variant="bodyLink">Data Processing Agreement</Typography>
        </a>{' '}
        was agreed to on behalf of {schoolName}
        {dpaSignedDetails()}.
      </>
    ) : (
      <>
        I, {firstName} {lastName}, have read and agree to the terms of the{' '}
        <a href={paths.KEY_RESOURCES} target="_blank" rel="noreferrer">
          <Typography variant="bodyLink">Data Processing Agreement</Typography>
        </a>{' '}
        on behalf of {schoolName} and am authorised to do so.
      </>
    );

  const dataProcessingCheckbox = () =>
    checkboxField(
      dpaInPlace || dpaAccepted,
      setDpaAccepted,
      dpaText(),
      type === 'school' && dpaInPlace
    );

  const marketingCheckbox = () =>
    checkboxField(
      optingInForMarketing,
      setOptingInForMarketing,
      'I would like to receive updates about new resources available, including Feel Good Friday.'
    );

  const dpaSection = () => (
    <Stack spacing={8}>
      <Typography variant="bodyBold" component="p">
        Data Processing Agreement
      </Typography>
      {dataProcessingCheckbox()}
    </Stack>
  );

  const recruitmentLessonsToolTip = () => (
    <Tooltip arrow title={<RecruitmentTooltipText />} placement="top">
      <InfoIcon sx={{ color: 'paintItBlack.40' }} />
    </Tooltip>
  );

  const recruitmentLessonsSection = () => (
    <Stack spacing={8}>
      <Box display="flex" alignItems="center">
        <Typography variant="bodyBold" component="p">
          Hosting Recruitment Lessons
        </Typography>
        {recruitmentLessonsToolTip()}
      </Box>
      {checkboxField(
        canHostRecruitmentLessons,
        setCanHostRecruitmentLessons,
        'We are open to supporting Rocksteady with the hosting of Recruitment Lessons.'
      )}
      {!currentCanHostRecruitmentLessons && canHostRecruitmentLessons && (
        <Alert severity="info">We will be in touch with you shortly to discuss.</Alert>
      )}
    </Stack>
  );

  const renderSubmitButton = () => (
    <Button
      label="Save Changes"
      onClick={handleSubmit}
      color="secondary"
      style={{ width: 'fit-content' }}
      loading={loading}
      disabled={(optingInForMarketing === currentOptInValue && !schoolPrefsChanged) || loading}
    />
  );

  const marketingSection = () => (
    <Stack spacing={8} onSubmit={handleSubmit}>
      <Typography variant="bodyBold" component="p">
        Marketing Preferences
      </Typography>
      {errors.user && renderErrorAlert()}
      {marketingCheckbox()}
    </Stack>
  );

  const schoolPreferences = () => (
    <>
      {dpaSection()}
      {recruitmentLessonsSection()}
      <SatsPreferences satsPreferences={satsPreferences} setSatsPreferences={setSatsPreferences} />
      {errors.school && renderErrorAlert()}
    </>
  );

  return (
    <Stack spacing={24}>
      {type === 'user' ? marketingSection() : schoolPreferences()}
      {renderSubmitButton()}
    </Stack>
  );
}
