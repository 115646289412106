/* eslint-disable no-use-before-define */
import { Alert, AlertTitle, Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import { Button } from '@rocksteady-music-school/rms-ui';
import StringUtils from '../../utils/StringUtils';
import response from '../../consts/actions';
import TermsCheckboxes from './TermsCheckboxes';
import InstrumentPreferences from './InstrumentPreferences';
import PupilDetails from './PupilDetails';
import { useUserData } from '../../contexts/UserContext';
import { useAuth } from '../../contexts/AuthContext';
import SchoolSubmittedKidDatumAPI from '../../api/SchoolSubmittedKidDatumAPI';

function MatchFundingForm() {
  const { schoolUrn, dpa: dpaInPlace } = useUserData();
  const { user, setUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const initialFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    yearGroup: null,
    firstChoice: 'no_preference',
    secondChoice: 'no_preference',
    notes: ''
  };

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [wouldDos, setWouldDos] = useState([]);
  const [formState, setFormState] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [dpaAccepted, setDpaAccepted] = useState(dpaInPlace || false);
  const [errorMsg, setErrorMsg] = useState(null);

  const failedSubmit = formState === response.ERROR;
  const formSubmitLoading = formState === response.LOADING;

  const { email, firstName, lastName, yearGroup, notes, firstChoice, secondChoice } = formValues;

  // This ensures that if the user signed the DPA when submitting the form,
  // they will be able to submit the form again for a new kid, without having to refresh the page
  useEffect(() => {
    setDpaAccepted(dpaInPlace || false);
  }, [dpaInPlace]);

  const emailValid = () => {
    if (!email) return true; // The email field can be empty

    return StringUtils.isEmailIsh(email);
  };

  const emailInvalid = showValidationErrors && !emailValid();

  const formValid = () =>
    emailValid() &&
    firstName &&
    lastName &&
    yearGroup &&
    termsAccepted &&
    dpaAccepted &&
    !StringUtils.containsEmoji(notes);

  const valueMissing = (name) => {
    if (!showValidationErrors) return false;

    if (!formValues[name]) return true;

    // Check that it contains something other than whitespace
    return !formValues[name].toString().trim();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShowValidationErrors(false);
    setFormValues({ ...formValues, [name]: value });
  };

  const formParams = () => ({
    school_submitted_kid_datum: {
      first_name: firstName,
      last_name: lastName,
      year: yearGroup,
      parent_email: email,
      // We need to set the initial form value to an empty string rather than null/undefined
      // in order for the MUI component to behave as expected
      // but we don't want to send an empty string over to the DB if the user hasn't entered anything
      notes: notes || undefined,
      instrument_first_choice: firstChoice,
      instrument_second_choice: secondChoice,
      would_do_drums: wouldDos.includes('drums'),
      would_do_guitar: wouldDos.includes('guitar'),
      would_do_keyboard: wouldDos.includes('keyboard'),
      would_do_vocals: wouldDos.includes('vocals'),
      accepted_privacy_policy: dpaAccepted,
      urn: schoolUrn,
      schools_portal_user_id: user.id,
      dpa_accepted: dpaInPlace ? null : dpaAccepted // Only set the param if the DPA hasn't already been signed
    }
  });

  const clearForm = () => {
    setFormValues(initialFormValues);
    setWouldDos([]);
    setTermsAccepted(false);
    setDpaAccepted(dpaInPlace || false);
  };

  const updateUserDataWithNewDpa = (newDpa) =>
    setUser((currentUserData) => ({
      ...currentUserData,
      attributes: {
        ...currentUserData.attributes,
        school: {
          ...currentUserData.attributes.school,
          dpa: newDpa
        }
      }
    }));

  const postKidDataForm = () =>
    SchoolSubmittedKidDatumAPI.postFormData(formParams())
      .then(() => {
        clearForm();
        setFormState(response.SUCCESS);
        enqueueSnackbar('Thank you for submitting, our schools team will be in touch shortly.', {
          variant: 'success'
        });
        if (!dpaInPlace) updateUserDataWithNewDpa(dpaAccepted);
      })
      .catch((e) => {
        setFormState(response.ERROR);
        const error = e.response?.data?.error || e.response?.data?.errors || e.message;
        setErrorMsg(error);
      });

  const handleSubmit = (event) => {
    event.preventDefault();

    setShowValidationErrors(true);

    if (formValid()) {
      setShowValidationErrors(false);
      setFormState(response.LOADING);
      postKidDataForm();
    }
  };

  const alertMsg = () => {
    if (Array.isArray(errorMsg)) {
      return (
        <ul style={{ listStylePosition: 'inside', paddingLeft: 0, marginBottom: 0, marginTop: 12 }}>
          {errorMsg.map((er) => (
            <li key={uuidv4()}>{er}</li>
          ))}
        </ul>
      );
    }
    return errorMsg;
  };

  const renderErrorAlert = () => (
    <Alert severity="error" sx={styles.errorAlert}>
      {Array.isArray(errorMsg) && <AlertTitle>There were some errors with the form</AlertTitle>}
      {alertMsg()}
    </Alert>
  );

  const pupilNotes = () => (
    <>
      <TextField
        fullWidth
        id="form-notes"
        label="Pupil Record Notes"
        name="notes"
        value={notes}
        onChange={handleInputChange}
        sx={{
          margin: 0,
          marginBottom: 6,
          marginTop: 19
        }}
        error={StringUtils.containsEmoji(notes)}
        helperText={StringUtils.containsEmoji(notes) && 'Please avoid using emojis in the form'}
      />
      <Typography variant="bodySecondary">
        Anything else you would like to let us know about the child being enrolled, such as medical
        records, or other important information. Please also let us know if you have a preference on
        whether this child is recorded as a paid or free space on the invoice. If you have no
        preference, places will be allocated automatically.
      </Typography>
    </>
  );

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <Stack spacing={19} my={27} sx={styles.formGroup}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            {failedSubmit && renderErrorAlert()}
            <PupilDetails
              formValues={formValues}
              setFormValues={setFormValues}
              valueMissing={valueMissing}
              handleInputChange={handleInputChange}
              emailInvalid={emailInvalid}
            />
          </Grid>
          <Grid item xs={12} mt={24}>
            <InstrumentPreferences
              formValues={formValues}
              setFormValues={setFormValues}
              handleInputChange={handleInputChange}
              wouldDos={wouldDos}
              setWouldDos={setWouldDos}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            {pupilNotes()}
          </Grid>
          <Grid item xs={12} lg={8.5}>
            <TermsCheckboxes
              showValidationErrors={showValidationErrors}
              termsAccepted={termsAccepted}
              setTermsAccepted={setTermsAccepted}
              dpaAccepted={dpaAccepted}
              setDpaAccepted={setDpaAccepted}
            />
            <Button
              type="submit"
              color="secondary"
              label="Register"
              style={styles.submitBtn}
              loading={formSubmitLoading}
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}

const styles = {
  formGroup: {
    marginTop: 3,
    '>fieldset+fieldset': { marginTop: 0 }
  },
  passwordLink: { mb: 20, mt: 12 },
  submitBtn: { width: 'fit-content', marginTop: 16 },
  errorAlert: { mb: 26 }
};

export default MatchFundingForm;
