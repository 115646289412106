/* eslint-disable class-methods-use-this */

import HTTPWrapper from '../utils/HTTPWrapper';

class MusicEducationVideoAPI {
  getVideos = (params) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/music_education_videos.json`,
      config: { params }
    });
}

export default new MusicEducationVideoAPI();
