/* eslint-disable react/prop-types */
import { Typography, Box, Link, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Page, ContactCard } from '@rocksteady-music-school/rms-ui';
import SchoolAPI from '../api/SchoolAPI';
import { useUserData } from '../contexts/UserContext';
import DBSModal from '../components/DBSModal.js/DBSModal';
import contactDetails from '../consts/contactDetails';
import externalPaths from '../consts/externalPaths';

export default function ContactsPage() {
  const { timetables, schoolUrn, isActiveSchool, isAdmin } = useUserData();

  const [teamDataLoading, setTeamDataLoading] = useState(false);
  const [DBSDataLoading, setDBSDataLoading] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const [DBSData, setDBSData] = useState(null);
  const [timetableData, setTimetableData] = useState(timetables[0]);
  const [showBandLeaderModal, setShowBandLeaderModal] = useState(false);
  const [showCoverBandLeaderModal, setShowCoverBandLeaderModal] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const notCoverBLs = () => teamData?.band_leaders?.filter((bl) => !bl?.is_cover);

  const displayedBandLeader = () =>
    notCoverBLs()?.find((bl) => bl.timetable_id === timetableData?.id);

  const fetchTeamData = () => {
    setTeamDataLoading(true);
    SchoolAPI.getTeamData(schoolUrn)
      .then((res) => {
        setTeamData(res.data);
        setTeamDataLoading(false);
      })
      .catch(() => setFetchError(true));
  };

  useEffect(() => {
    if (schoolUrn) {
      fetchTeamData();
    }
  }, [schoolUrn]);

  const fetchDBSData = () => {
    setDBSDataLoading(true);
    SchoolAPI.getDBSInfo(schoolUrn)
      .then((res) => {
        setDBSData(res.data.data);
        setDBSDataLoading(false);
      })
      .catch(() => setFetchError(true));
  };

  const handleTTChange = (event) => {
    setTimetableData(event?.target?.value);
  };

  // generate an array of objects containing key contacts for each timetable
  // with objects containing duplicates/null or "" under any given key removed
  const filteredContactsToDisplay = (key) => _.filter(_.uniqBy(teamData?.key_contacts, key), key);

  const teamPanel = (title, cards, text) => (
    <Grid item xs={12} lg={6} xl={5} display="flex">
      <Box width="100%" padding={24} backgroundColor="blueSuedeShoes.10" borderRadius={5}>
        <Typography variant="h2" sx={{ color: 'primary.main' }} mb={8} mt={8}>
          {title}
        </Typography>
        {text && <Box>{text}</Box>}
        {cards}
      </Box>
    </Grid>
  );

  const linkText = () => (
    <Typography variant="body">
      If you would like to update your school contacts, please{' '}
      <Link variant="bodyLink" href={`mailto:${contactDetails.SCHOOLS_EMAIL}`}>
        contact the Schools Hotline.
      </Link>
    </Typography>
  );

  const coverBandleader = () => teamData?.band_leaders?.find((bl) => bl?.is_cover);

  const bandLeaderDBSInfo = (bandLeader) =>
    DBSData?.find((profile) => profile?.id === bandLeader?.staff_profile_id?.toString());

  const bandLeaderSupportDBSInfo = (bandLeader) =>
    DBSData?.find(
      (profile) => profile?.id === bandLeader?.band_leader_support_profile_id?.toString()
    );

  const handleBandLeaderDBSClick = () => {
    setShowBandLeaderModal(true);
    if (!DBSData) {
      fetchDBSData();
    }
  };

  const handleCoverBandLeaderDBSClick = () => {
    setShowCoverBandLeaderModal(true);
    if (!DBSData) {
      fetchDBSData();
    }
  };

  const teamCards = () => (
    <Box mt={32}>
      <ContactCard
        heading="Schools Hotline"
        subHeadingText="If you would like to speak to someone at Rocksteady about anything at all, please call or email the Schools Hotline and we will be very happy to help."
        writeTo={{
          url: externalPaths.ENQUIRY_FORM,
          text: 'Submit a school enquiry form'
        }}
        bookCallUrl={externalPaths.BOOK_CALL_FORM}
        tel={contactDetails.SCHOOLS_TEL}
        telInfo="(Mon-Fri, 8am-4pm including school holidays)"
        mainEmail={contactDetails.SCHOOLS_EMAIL}
      />
      {isActiveSchool && (
        <ContactCard
          noBandleader={!displayedBandLeader()}
          data-testid="bandleader-card"
          subHeadingText="If you would like to contact your Band Leader or any of the Education Team, please email the relevant contact below."
          bandLeaderName={displayedBandLeader()?.name}
          mainEmail={displayedBandLeader()?.email}
          bandLeaderSupport={displayedBandLeader()?.band_leader_support || '-'}
          bandLeaderSupportEmail={displayedBandLeader()?.band_leader_support_email}
          regionalManager={displayedBandLeader()?.regional_manager || '-'}
          regionalManagerEmail={displayedBandLeader()?.regional_manager_email}
          btnText={isAdmin ? 'Show DBS Details' : null}
          showAvatar
          timetables={timetables}
          selectedTT={timetableData}
          handleTTChange={handleTTChange}
          avatarUrl={`${process.env.REACT_APP_ABBEY_ROAD_URL}${
            displayedBandLeader()?.profile_picture_url
          }`}
          onButtonClick={isAdmin ? handleBandLeaderDBSClick : null}
          heading="Education Team"
          style={{ marginTop: 16 }}
          loading={teamDataLoading}
        />
      )}
      {isActiveSchool && coverBandleader() && (
        <ContactCard
          data-testid="bandleader-card"
          preHeading="Cover Band Leader"
          mainEmail={coverBandleader().email}
          bandLeaderSupport={coverBandleader().band_leader_support || '-'}
          bandLeaderSupportEmail={coverBandleader().band_leader_support_email}
          regionalManager={coverBandleader().regional_manager || '-'}
          regionalManagerEmail={coverBandleader().regional_manager_email}
          btnText={isAdmin ? 'Show DBS Details' : null}
          showAvatar
          avatarUrl={`${process.env.REACT_APP_ABBEY_ROAD_URL}${
            coverBandleader()?.profile_picture_url
          }`}
          onButtonClick={isAdmin ? handleCoverBandLeaderDBSClick : null}
          bandLeaderName={coverBandleader().name}
          style={{ marginTop: 16 }}
          loading={teamDataLoading}
        />
      )}
      <ContactCard
        preHeading="Safeguarding Contact"
        heading={teamData?.safeguarding_contact?.name}
        mainEmail={teamData?.safeguarding_contact?.email}
        tel={contactDetails.SCHOOLS_TEL}
        style={{ marginTop: 16 }}
        loading={teamDataLoading}
      />
      <ContactCard
        preHeading="Director of Education"
        mainEmail={teamData?.education_director?.email}
        heading={teamData?.education_director?.name}
        style={{ marginTop: 16 }}
        loading={teamDataLoading}
      />
    </Box>
  );

  const adminCards = () =>
    filteredContactsToDisplay('admin_contact_name').map((timetable) => (
      <ContactCard
        preHeading="Admin"
        mainEmail={timetable.admin_contact_email}
        adminContact
        heading={timetable.admin_contact_name}
        style={{ marginTop: 16 }}
        key={timetable.timetable_id}
      />
    ));

  const mainContactCards = () =>
    filteredContactsToDisplay('primary_contact_name').map((timetable) => (
      <ContactCard
        preHeading={timetable.primary_contact_role}
        mainEmail={timetable.primary_contact_email}
        mainContact
        heading={timetable.primary_contact_name}
        style={{ marginTop: 16 }}
        key={timetable.timetable_id}
      />
    ));

  const headContactCards = () =>
    filteredContactsToDisplay('head_teacher_name').map((timetable) => (
      <ContactCard
        preHeading="Head Teacher"
        heading={timetable.head_teacher_name}
        mainEmail={timetable.head_teacher_email}
        style={{ marginTop: 16 }}
        key={timetable.timetable_id}
      />
    ));

  const contactsCards = () => (
    <Box mt={40}>
      {mainContactCards()}
      {adminCards()}
      {headContactCards()}
    </Box>
  );

  const handleModalClose = () => {
    setShowBandLeaderModal(false);
    setShowCoverBandLeaderModal(false);
  };

  return (
    <Page error={fetchError}>
      {isAdmin && (
        <DBSModal
          open={showBandLeaderModal}
          onClose={handleModalClose}
          blDBSInfo={bandLeaderDBSInfo(displayedBandLeader())?.attributes}
          blsDBSInfo={bandLeaderSupportDBSInfo(displayedBandLeader())?.attributes}
          loading={DBSDataLoading}
        />
      )}
      {isAdmin && (
        <DBSModal
          open={showCoverBandLeaderModal}
          onClose={handleModalClose}
          blDBSInfo={bandLeaderDBSInfo(coverBandleader())?.attributes}
          blsDBSInfo={bandLeaderSupportDBSInfo(coverBandleader())?.attributes}
          loading={DBSDataLoading}
        />
      )}
      <Typography variant="h1" mt={{ xs: 0, lg: 24 }}>
        Contacts
      </Typography>
      <Grid container direction="row" columnSpacing={40} rowSpacing={40} mt={8}>
        {teamPanel('your rocksteady team', teamCards())}
        {/* School Contacts are stored in the timetable data. Since inactive schools don't have TTs, we are hiding this column */}
        {isActiveSchool && teamPanel('your school contacts', contactsCards(), linkText())}
      </Grid>
    </Page>
  );
}
