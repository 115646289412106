/* eslint-disable class-methods-use-this */
import HTTPWrapper from '../utils/HTTPWrapper';

class DocumentsAPI {
  getDocuments = () =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools_documents.json`
    });
}

export default new DocumentsAPI();
