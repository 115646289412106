import { useMediaQuery } from '@mui/material';
import ClippedImgCardContainer from '../MiniDashboard/ClippedImgCardContainer';
import bgImg from '../../assets/images/fgf_download.png';
import LinkToPage from '../MiniDashboard/LinkToPage';
import SchoolsUserEventLogAPI from '../../api/SchoolsUserEventLogAPI';
import { useUserData } from '../../contexts/UserContext';
import { useAuth } from '../../contexts/AuthContext';

export default function FGFDownloadButton({ downloadPath }) {
  const mobileView = useMediaQuery('(max-width:475px)');
  const { isActiveSchool, schoolUrn } = useUserData();
  const { user } = useAuth();

  const logResourceAccess = () => {
    SchoolsUserEventLogAPI.createLog({
      schools_user_event_log: {
        schools_portal_user_id: user.id,
        school_is_active_rs: isActiveSchool,
        urn: schoolUrn,
        event_type: 'resource',
        event_item: 'download',
        value: 0,
        description: 'FGF Lesson Plan'
      }
    });
  };

  const downloadBtn = () => (
    <a
      style={{ display: 'contents' }}
      href={`${process.env.REACT_APP_ABBEY_ROAD_URL}${downloadPath}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={logResourceAccess}>
      <ClippedImgCardContainer
        shadowLeft
        bgImg={bgImg}
        clipPath={
          mobileView
            ? 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)' // The image will be hidden in mobile view
            : 'polygon(71% 0, 100% 0, 100% 100%, 81% 100%)'
        }
        floatImgRight
        bgcolor="whiteWedding.main"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        height="100%"
        boxSizing="border-box"
        paddingLeft={15}>
        <LinkToPage text="Curriculum &amp; lesson plan" showIcon download />
      </ClippedImgCardContainer>
    </a>
  );

  return downloadBtn();
}
