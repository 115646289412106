/* eslint-disable no-use-before-define */
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { Page } from '@rocksteady-music-school/rms-ui';
import banner from '../assets/images/band_lessons_banner.png';
import card1 from '../assets/images/band_lessons_card1.png';
import card2 from '../assets/images/band_lessons_card2.png';
import card3 from '../assets/images/band_lessons_card3.png';

import ErrorPage from './ErrorPage';
import BandLessonsForm from '../components/BandLessons/BandLessonsForm';
import BandLessonsCard from '../components/BandLessons/BandLessonsCard';
import paths from '../consts/paths';
import BandLessonsVideo from '../components/BandLessons/BandLessonsVideo';
import { useUserData } from '../contexts/UserContext';

export default function BandLessonsPage() {
  const { isActiveSchool } = useUserData();

  if (isActiveSchool) {
    return <ErrorPage content="pageNotFound" />;
  }

  const [formVisible, setFormVisible] = useState(false);

  useEffect(() => {
    if (formVisible) {
      document.getElementById('callback-form').scrollIntoView({ behavior: 'smooth' });
    }
  }, [formVisible]);

  const header = () => (
    <Box sx={styles.header}>
      <Box maxWidth={595} paddingY={60} paddingX={24}>
        <Typography variant="h1" color="whiteWedding.main" mb={25} whiteSpace="pre-line">
          Rocksteady In-School{'\n'}Band Lessons
        </Typography>
        <Typography variant="bodyBold" component="p" color="whiteWedding.main" mb={20}>
          Rocksteady deliver peripatetic rock and pop band lessons in primary schools using a unique
          and proven child-led pedagogy, which enables any child in your school to learn to play an
          instrument in a band with their peers.
        </Typography>
        <Typography variant="bodyBold" component="p" color="whiteWedding.main" mb={20}>
          Enjoyed by tens of thousands of children in UK primary schools each week, the program is
          designed to grow confidence and support wellbeing, helping schools maximise children’s
          potential back in the classroom too.
        </Typography>
        <Typography variant="bodyBold" component="p" color="whiteWedding.main">
          Click the video below to hear from other primary schools benefiting from the program.
        </Typography>
      </Box>
    </Box>
  );

  const description = () => (
    <Grid container marginBottom={40}>
      <Grid item xs={12} lg={8}>
        <Typography variant="h2" color="primary.main" mb={16}>
          Could Rocksteady support your pupils?
        </Typography>
        <Typography variant="body">
          Designed specifically for UK primary schools, children work through a 300-skill curriculum
          in band rehearsals, culminating in end-of-term concerts. In many cases lessons are offered
          at no cost to the school, however there are also school-funded options as well as free
          bursary spaces. Rocksteady provide the teachers, instruments and look after all the
          administration, it’s an easy choice for schools which makes a huge impact.
        </Typography>
      </Grid>
    </Grid>
  );

  const openTheForm = () => {
    setFormVisible(true);
    document.getElementById('callback-form').scrollIntoView({ behavior: 'smooth' });
  };

  const cards = () => (
    <Grid container spacing={16}>
      <Grid item xs={12} sm={4}>
        <BandLessonsCard
          img={card1}
          title="Case Studies"
          content="Read how Rocksteady lessons are enhancing pupils school lives and helping all children enjoy the holistic benefits of music education"
          buttonProps={{
            label: 'View Case Studies',
            href: paths.CASE_STUDIES,
            openLinkInNewWindow: true
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <BandLessonsCard
          img={card2}
          title="Download our brochure"
          content="A more detailed overview of the program"
          buttonProps={{
            label: 'Download Brochure',
            href: paths.RS_BROCHURE,
            openLinkInNewWindow: true
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <BandLessonsCard
          img={card3}
          title="Get in touch"
          content="The quickest way to answer any questions you have and find out if Rocksteady in-school lessons would work for your school is to have a chat. You may also be able to book a free live music assembly."
          buttonProps={{ label: 'Contact Us', onClick: openTheForm }}
        />
      </Grid>
    </Grid>
  );

  const pageContent = () => {
    return (
      <Box>
        {header()}
        <BandLessonsVideo />
        {description()}
        {cards()}
        <Box id="callback-form" sx={{ display: formVisible ? 'block' : 'none' }}>
          <BandLessonsForm />
        </Box>
      </Box>
    );
  };

  return <Page>{pageContent()}</Page>;
}

const styles = {
  header: {
    backgroundImage: `url(${banner})`,
    backgroundSize: 'cover',
    borderRadius: '25px'
  }
};
