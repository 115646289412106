export default {
  user: {
    id: '1',
    attributes: {
      role: 'admin',
      school: {
        name: 'Rocksteady Music School',
        status: 'active'
      }
    }
  },
  schoolContact: {
    id: '1',
    attributes: {
      first_name: 'Princess',
      last_name: 'Peach'
    }
  },
  schoolContactLinks: []
};
