/* eslint-disable class-methods-use-this */
import HTTPWrapper from '../utils/HTTPWrapper';

class SalesLeadAPI {
  create = (data) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/sales_leads.json`,
      data
    });
}

export default new SalesLeadAPI();
