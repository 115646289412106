import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserData } from '../contexts/UserContext';
import { useAuth } from '../contexts/AuthContext';
import SchoolsUserEventLogAPI from '../api/SchoolsUserEventLogAPI';
import StringUtils from '../utils/StringUtils';

// Including this hook within a page/component will cause logs to
// be sent to Abbey Road upon the page either being deemed to have
// been navigated away from, or the page is no longer visible to
// the user (utilising the browser 'visibilitystate' API).
// Due to technical complexities with sending HTTP requests when the
// app/site has been 'left', currently logs will not be sent upon
// navigation away from the site.
export default function usePageVisitLogger() {
  const { pathname } = useLocation();

  const LOGS_TO_BE_SENT = Boolean(parseInt(process.env.REACT_APP_SEND_PAGE_VISIT_LOGS, 10));

  const { user, isAuthenticated } = useAuth();
  const { isActiveSchool, schoolUrn } = useUserData();

  const titleizePath = () => StringUtils.titleize(pathname.slice(1).replace(/-/, ' '));
  const calculateSeconds = (startTime, endTime) => Math.round((endTime - startTime) / 1000);

  const logPageVisit = (duration) => {
    SchoolsUserEventLogAPI.createLog({
      schools_user_event_log: {
        schools_portal_user_id: user.id,
        school_is_active_rs: isActiveSchool,
        urn: schoolUrn,
        event_type: 'navigation',
        event_item: 'page_visit',
        value: duration,
        description: `Visit ${titleizePath()} Page`
      }
    });
  };

  useEffect(() => {
    let arriveTime = Date.now();
    const handleVisibilityChange = () => {
      if (isAuthenticated && LOGS_TO_BE_SENT && document.visibilityState === 'hidden') {
        logPageVisit(calculateSeconds(arriveTime, Date.now()));
      } else {
        arriveTime = Date.now();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      // This should handle instances of navigation away from the page
      if (isAuthenticated && LOGS_TO_BE_SENT && document.visibilityState === 'visible') {
        logPageVisit(calculateSeconds(arriveTime, Date.now()));
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
}
