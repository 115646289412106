/* eslint-disable no-use-before-define */

import { Typography, Box, FormGroup, TextField, Alert, Stack } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button } from '@rocksteady-music-school/rms-ui';
import paths from '../consts/paths';
import StringUtils from '../utils/StringUtils';
import UserAPI from '../api/UserAPI';
import AuthContainer from '../components/AuthContainer';
import BackToPathLink from '../components/BackToPathLink/BackToPathLink';

export default function RequestPasswordResetPage() {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [invitationSent, setInvitationSent] = useState(null);
  const [email, setEmail] = useState(location?.state?.email);
  const [errorResponse, setErrorResponse] = useState(null);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [passwordResetLoading, setPasswordResetLoading] = useState(false);
  const [animationSlidesIn, setAnimationSlidesIn] = useState(true);

  const [slideInRef] = useOutletContext();

  const navigate = useNavigate();

  const onExited = () => navigate(paths.LOG_IN);

  const redirectedHere = !!location?.state?.redirect;

  const emailValid = () => StringUtils.isEmailIsh(email);

  const emailInvalid = showValidationErrors && !emailValid();

  const heading = (text) => (
    <Typography mb={24} variant="h1">
      {text}
    </Typography>
  );

  const text = (content, variant) => (
    <Box mb={35}>
      <Typography variant={variant}>{content}</Typography>{' '}
    </Box>
  );

  const handleNavigatingBack = () => {
    setAnimationSlidesIn(false);
  };

  const backToLogin = () => (
    <BackToPathLink text="Return to sign in" onNavigatingBack={handleNavigatingBack} />
  );

  const emailField = () => (
    <TextField
      margin="none"
      value={email || ''}
      required={!(redirectedHere && email)}
      fullWidth
      id="email"
      label="Email Address"
      name="email"
      autoComplete="email"
      type="email"
      autoFocus
      onChange={handleInputChange}
      error={emailInvalid}
      helperText={emailInvalid ? 'Please enter a valid email address' : ''}
      disabled={!!(redirectedHere && email)}
    />
  );

  const handleInputChange = (e) => {
    const { value } = e.target;
    setErrorResponse(null);
    setShowValidationErrors(false);
    setEmail(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowValidationErrors(true);

    if (emailValid()) {
      setPasswordResetLoading(true);
      resetPassword();
    }
  };

  const resetPassword = async () => {
    setErrorResponse(null);

    try {
      await UserAPI.setPassword({ password: { email } });
      setInvitationSent(true);
      enqueueSnackbar('Password reset email requested', { variant: 'success' });
    } catch ({ message }) {
      setErrorResponse(message);
    } finally {
      setPasswordResetLoading(false);
    }
  };

  const renderAlert = (type, message) => (
    <Alert severity={type} sx={{ mb: 24 }}>
      {message}
    </Alert>
  );

  const passwordForm = () => (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <FormGroup sx={{ width: { xs: '100%', sm: '60%' } }}>
        {emailField()}
        <Button
          type="submit"
          label="Request Link"
          color="secondary"
          style={styles.submitBtn}
          loading={passwordResetLoading}
        />
      </FormGroup>
    </Box>
  );

  const requestResetContent = () => (
    <>
      {backToLogin()}
      {heading('Password Reset')}
      {errorResponse &&
        renderAlert(
          'error',
          `An error occurred: ${errorResponse}. Please contact us if the problem persists.`
        )}
      {!(redirectedHere && email) &&
        text("Please enter your email address and we'll send you a password reset link.", 'body')}
      {passwordForm()}
    </>
  );

  const emailSentText = () => (
    <Box>
      <Typography mb={20} variant="body" component="p">
        The link for resetting your password has been sent to <strong>{email}</strong>.
      </Typography>
      <Typography mb={20} variant="body" component="p">
        It can take up to 5 minutes for your email to arrive.
      </Typography>
      <Typography mb={20} variant="body" component="p">
        The link is only valid for the next 12 hours. If you can&apos;t find the email, please check
        your junk folder.
      </Typography>
    </Box>
  );

  const emailSentConfirmation = () => (
    <Stack>
      <Box mt={50}>
        {heading('Email sent')}
        {errorResponse &&
          renderAlert(
            'error',
            `An error occurred: ${errorResponse}. Please contact us if the problem persists.`
          )}
        {emailSentText()}
      </Box>
      <Box display="flex">
        <Typography variant="bodyBold">Still haven&apos;t received the email?</Typography>
        <Typography onClick={resetPassword} variant="bodyLink" ml={5}>
          Send again
        </Typography>
      </Box>
      <Button
        color="secondary"
        sx={styles.returnBtn}
        label="Return to Sign In"
        onClick={handleNavigatingBack}
      />
    </Stack>
  );

  return (
    <AuthContainer
      animationSlidesIn={animationSlidesIn}
      containerRef={slideInRef}
      onExitedCallback={onExited}
      key={`${invitationSent}`}>
      {invitationSent ? emailSentConfirmation() : requestResetContent()}
    </AuthContainer>
  );
}

const styles = {
  submitBtn: { width: 'fit-content', marginTop: 24 },
  returnBtn: { width: 'fit-content', mt: 24 }
};
