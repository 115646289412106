/* eslint-disable react/prop-types */
import { UserProviderContext } from '../contexts/UserContext';
import { useAuth } from '../contexts/AuthContext';
import userRoles from '../consts/userRoles';

// Deprecating UserProvider to instead focus user/school contact context
// within AuthContext, and introduce a SchoolsProvider for accessing relevant
// school data.
export default function UserProvider({ children }) {
  const { user, schoolContact } = useAuth();

  const firstName = schoolContact?.attributes?.first_name;
  const lastName = schoolContact?.attributes?.last_name;
  const email = schoolContact?.attributes?.email;
  const optedInForMarketing = schoolContact?.attributes?.opted_in_for_marketing;

  const role = user?.attributes?.role;
  const isAdmin = role === userRoles.ADMIN.roleName;
  const isStaff = role === userRoles.STAFF.roleName;
  const isBasic = role === userRoles.BASIC.roleName;
  const isAdminOrStaff = isAdmin || isStaff;

  const timetables = user?.attributes?.timetables || [];
  const isActiveSchool = user?.attributes?.school?.status === 'active';
  const schoolUrn = user?.attributes?.school?.urn;
  const schoolName = user?.attributes?.school?.name;
  const schoolPostcode = user?.attributes?.school?.postcode;
  const dpa = user?.attributes?.school?.dpa;
  const dpaSignedBy = user?.attributes?.school?.dpa_signed_by;
  const dpaSignedOn = user?.attributes?.school?.dpa_signed_on;
  const dpaSigneeEmail = user?.attributes?.school?.dpa_signee_email;
  const schoolLogo = user?.attributes?.school?.logo;
  const activeSince = user?.attributes?.school?.active_since;

  const currentSchoolYear = user?.attributes?.current_school_year;
  const canHostRecruitmentLessons = user?.attributes?.school?.can_host_recruitment_lessons;
  const satsPreference = user?.attributes?.school?.sats_preference;
  const ks1Sats = user?.attributes?.school?.ks1_sats_on_rs_days;
  const satsRoomNotes = user?.attributes?.school?.sats_room_change_notes;
  const satsCutoffDate = user?.attributes?.sats_preference_cutoff_date;

  const value = {
    firstName,
    lastName,
    email,
    optedInForMarketing,
    role,
    isAdmin,
    isStaff,
    isBasic,
    isAdminOrStaff,
    timetables,
    isActiveSchool,
    schoolUrn,
    schoolName,
    schoolLogo,
    schoolPostcode,
    dpa,
    dpaSignedBy,
    dpaSignedOn,
    dpaSigneeEmail,
    currentSchoolYear,
    canHostRecruitmentLessons,
    activeSince,
    satsPreferences: {
      sats_preference: satsPreference,
      ks1_sats_on_rs_days: ks1Sats,
      sats_room_change_notes: satsRoomNotes
    },
    satsCutoffDate
  };

  return <UserProviderContext properties={value}>{children}</UserProviderContext>;
}
